'use strict';

angular.module('kljDigitalLibraryApp')

.service('JournalsOpenedTabService', ["$q", "common", "appConfig", function($q, common, appConfig) {

    this.openedTabs = [];
    this.isIdAlreadyPresent = function(id) {

        for (var index in this.openedTabs) {

            if (this.openedTabs[index]._id === id) {
                return true;
            }
        }
        return false;
    }

    //set party data from api
    this.setPartyData = function(dataSet) {

        for (var index in dataSet) {
            if (!this.isIdAlreadyPresent(dataSet[index]._id)) {
                this.openedTabs.push(dataSet[index]);
            }
        }
    }

    //get all opened tab details
    this.getAll = function() {
        return this.openedTabs;
    }

    //Add single party
    this.addPartyData = function(party) {

        if (!this.isIdAlreadyPresent(party._id)) {
            this.openedTabs.push(party);
        }
        return this.openedTabs;
    }


    //Get Party names of the case
    this.getOpenedTabInformation = function(body) {
        var that = this;
        var url = '/api/case_journals/getTopicDetails';
        return common.callApi('POST',
            url,
            appConfig.EMPTY_STRING,
            appConfig.CONTENT_TYPE,
            body
        )

        .then(function(response) {
            that.setPartyData(response.data);
            console.log(that.openedTabs);
            return that.openedTabs;
        })
    }

    //Remove All opened tabs
    this.removeAllOpenesTabInformation = function() {
        this.openedTabs = [];
        return this.openedTabs;
    }

    //Remove specified opend tab
    this.remove = function(id) {

        var newOpenedTabs = [];
        for (var index in this.openedTabs) {
            if (this.openedTabs[index]._id != id) {
                newOpenedTabs.push(this.openedTabs[index]);
            }
        }
        this.openedTabs = newOpenedTabs;
        return this.openedTabs;
    }




}])